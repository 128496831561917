import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Layout from "components/Layout/Layout";
import { media } from "utils/Media";

const StyledXmasPage = styled.section`
 
`;

export default function Xmas23Page() {
  const data = {
    template: `signup-template`,
  };
  return (
    <Layout data={data}>
      <section className="xmas">
        <div className="ribbon">
          <StaticImage
            className="mobile"
            src="../../images/xmas/Ribbon-and-wreath-mobile.png"
          />
          <StaticImage
            className="md"
            src="../../images/xmas/Ribbon-and-wreath.png"
          />
          <StaticImage
            className="desktop"
            src="../../images/xmas/Ribbon-and-wreath-xl.png"
          />
        </div>
        <div className="extended-ribbon">
          <StaticImage
            class="mobile"
            src="../../images/xmas/Ribbon-and-wreath-mobile-extended.png"
          />
          <StaticImage
            class="md"
            src="../../images/xmas/Ribbon-and-wreath-extended-desktop.png"
          />
          <StaticImage
            class="desktop"
            src="../../images/xmas/Ribbon-and-wreath-xl-extended.png"
          />
        </div>
        <div className="tag">
          <a href="https://bookofmormonshop.co.uk/" target="_blank" rel="noopener noreferrer">
            <StaticImage src="../../images/xmas/Gift-Tag.png" />
          </a>
        </div>
        <div className="top-section">
          <div className="image">
            <StaticImage src="../../images/xmas/New-York-Times-logo.png" />
          </div>
          <h5 className="quote text-background">
            <strong>
              {" "}
              &#8220;THE BEST MUSICAL <br /> OF THE CENTURY&#8221;
            </strong>
          </h5>
          <p className="base-font text-background">
            Makes the best <br /> festive gift ever!
          </p>
        </div>
        <div className="main-image">
          <StaticImage src="../../images/xmas/Guy-with-basket-remove-bg-large.png" />
        </div>
        <div className="logo">
          <StaticImage
            class="mobile"
            src="../../images/xmas/Book-mormon-logo-mobile.png"
          />
          <StaticImage
            class="desktop"
            src="../../images/xmas/Book-mormon-logo.png"
          />
        </div>
        <div className="buttons">
          <div className="banner-top">
            <StaticImage
              class="mobile"
              src="../../images/xmas/top-bracket-mobile.png"
            />
            <StaticImage
              class="desktop"
              src="../../images/xmas/top-bracket.png"
            />
          </div>
          <div className="grid">
            <div className="left">
              <h3 className="text-background">LONDON</h3>
              <p className="text-background base-font">
                GIVE TICKETS <br /> AS GIFTS & <br /> ENJOY NO HASSLE <br />{" "}
                TICKET <br /> EXCHANGES
              </p>
              <Link to="/london/" className="red-button">
                <span>BOOK NOW!</span>
              </Link>
            </div>
            <div>
              <div className="gold-border" />
            </div>
            <div className="right">
              <h3 className="text-background">UK TOUR</h3>
              <p className="text-background base-font">
                NEW FROM 2024! SEATS FROM AS LITTLE AS £13
              </p>
              <div>
                <p className="text-background">MANCHESTER</p>
                <Link to="/manchester/" className="red-button">
                  <span>BOOK NOW!</span>
                </Link>
              </div>
              <div>
                <p className="text-background">EDINBURGH</p>
                <Link to="/edinburgh/" className="red-button">
                  <span>BOOK NOW!</span>
                </Link>
              </div>
              <div>
                <p className="text-background">BIRMINGHAM</p>
                <Link to="/birmingham/" className="red-button">
                  <span>BOOK NOW!</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="banner-top">
            <StaticImage
              class="mobile"
              src="../../images/xmas/lower-bracket-mobile.png"
            />
            <StaticImage
              class="desktop"
              src="../../images/xmas/lower-bracket.png"
            />
          </div>
        </div>
        <div className="footer">
          <div>
            <div className="signup">
              <a href="/london/" className="red-button">
                <span>EMAIL SIGN UP</span>
              </a>
            </div>
            <hr />
            <div className="social">
              <span>
                <a href="https://www.facebook.com/bookofmormonUK/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../images/xmas/facebook.png" />
                </a>
              </span>
              <span>
                <a href="https://twitter.com/bookofmormonuk/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../images/xmas/twitter.png" />
                </a>
              </span>
              <span>
                <a href="https://www.youtube.com/channel/UCQfNJ5Mxr8zC7lHgMDwkheA" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../images/xmas/youtube.png" />
                </a>
              </span>
              <span>
                <a href="https://www.instagram.com/bookofmormonuk/" target="_blank" rel="noopener noreferrer">
                  <StaticImage src="../../images/xmas/instagram.png" />
                </a>
              </span>
            </div>
            <div>
              <p>
                {" "}
                <strong>@BookofMormon</strong>
              </p>
            </div>
          </div>
          <div className="privacy">
            <p>
              &copy; 2023 &#8226; <a href="/london/privacy-policy/">Privacy Policy</a>{" "}
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}
